import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index";
Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? "/" : "/",
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("@/pages/users/login"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/pages/users/login"),
    },
    {
      path: "/answer",
      name: "usersAnswer",
      meta: { requiresAuth: true },
      component: () => import("@/pages/question/specific"),
      children: [
        {
          name: "USERS_ANSWER_DETAIL",
          path: ":_id",
          component: () => import("@/pages/question/specific/_id"),
        },
      ],
    },
    {
      path: "/news-letter",
      name: "newsLetter",
      meta: { requiresAuth: true },
      component: () => import("@/pages/news-letter/index"),
      children: [
        {
          name: "news-letter-new",
          path: "new",
          component: () => import("@/pages/news-letter/_id"),
        },
        {
          name: "news-letter-detail",
          path: ":_id",
          component: () => import("@/pages/news-letter/_id"),
        },
      ],
    },
    {
      path: "/users/list",
      name: "users",
      component: () => import("@/pages/users/index"),
      meta: { requiresAuth: true, adminAuth: true },
      children: [
        {
          name: "user-detail",
          path: ":_id",
          component: () => import("@/pages/users/specific/_id"),
          children: [
            {
              name: "user-point-history",
              path: "point-history",
              component: () => import("@/pages/point/specific/point-history"),
            },
            {
              name: "user-question-history",
              path: "question-history",
              component: () =>
                import("@/pages/question/specific/question-history"),
            },
            {
              name: "user-login-history",
              path: "login-history",
              component: () => import("@/pages/users/specific/login-history"),
            },
            {
              name: "user-auth-history",
              path: "auth-history",
              component: () => import("@/pages/users/specific/auth"),
            },
            {
              name: "user-recommender",
              path: "recommender",
              component: () => import("@/pages/users/specific/recommender"),
            },
            //@/pages/question/list
          ],
        },
        // {name: 'user-message', path: 'message', component: () => import('@/pages/users/message')},
      ],
    },
    {
      path: "/users/member/list",
      name: "member",
      component: () => import("@/pages/member/index"),
      meta: { requiresAuth: true, adminAuth: true },
      children: [
        /*{
          name: 'user-detail',
          path: ':_id',
          component: () => import('@/pages/users/specific/_id'),
          children: [
            { name: 'user-point-history', path: 'point-history', component: () => import('@/pages/point/specific/point-history') },
            { name: 'user-question-history', path: 'question-history', component: () => import('@/pages/question/specific/question-history') },
            { name: 'user-login-history', path: 'login-history', component: () => import('@/pages/users/specific/login-history') },
            { name: 'user-auth-history', path: 'auth-history', component: () => import('@/pages/users/specific/auth') },
            { name: 'user-recommender', path: 'recommender', component: () => import('@/pages/users/specific/recommender') }
            //@/pages/question/list
          ]
        },*/
        // {name: 'user-message', path: 'message', component: () => import('@/pages/users/message')},
      ],
    },
    {
      path: "/ad/list",
      name: "ad-list",
      component: () => import("@/pages/ad/index"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        breadCrumbs: [
          {
            to: "/list",
            text: "리스트",
          },
        ],
      },
      children: [
        {
          name: "list-specific",
          path: ":_id",
          component: () => import("@/pages/ad/_id"),
        },
      ],
    },
    {
      path: "/ad/traffic",
      name: "ad-traffic",
      component: () => import("@/pages/ad/traffic/traffic"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        breadCrumbs: [
          {
            to: "/traffic",
            text: "트래픽",
          },
        ],
      },
    },
    // {
    //   path: '/list/:_id',
    //   name: 'LIST_SPECIFIC',
    //   component: () => import('@/pages/project/_id'),
    //   // children: [{
    //   //   path: '', component: () => import('@/components/top'),
    //   // }],
    //   meta: {
    //     requiresAuth: true,
    //     breadCrumbs: [{
    //       to: '/list',
    //       text: '리스트'
    //     }, {
    //       to: '/list/:_id',
    //       text: '광고'
    //     }]
    //   }
    // },
    {
      path: "/payment",
      name: "PAYMENT",
      component: () => import("@/pages/payment/index"),
      meta: { requiresAuth: true, adminAuth: true },
    },
    {
      path: "/faq",
      name: "FAQ",
      component: () => import("@/pages/question/faq/index"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "faq-new",
          path: "new",
          component: () => import("@/pages/question/faq/_id"),
        },
        {
          name: "faq-detail",
          path: ":_id",
          component: () => import("@/pages/question/faq/_id"),
        },
      ],
    },
    {
      path: "/pointEvent",
      name: "pointEvent",
      component: () => import("@/pages/point/event/index"),
      meta: { requiresAuth: true, adminAuth: true },
      children: [
        {
          name: "point-event-new",
          path: "new",
          component: () => import("@/pages/point/event/_id"),
        },
        {
          name: "point-event-detail",
          path: ":_id",
          component: () => import("@/pages/point/event/_id"),
          children: [
            {
              name: "point-event-use-users",
              path: "users",
              component: () => import("@/pages/point/event/users"),
            },
          ],
        },
      ],
    },

    {
      path: "/notice",
      name: "NOTICE",
      component: () => import("@/pages/notice/index"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "notice-new",
          path: "new",
          component: () => import("@/pages/notice/_id"),
        },
        {
          name: "notice-detail",
          path: ":_id",
          component: () => import("@/pages/notice/_id"),
        },
      ],
    },
    /*{
      path: '/coupon',
      name: 'COUPON',
      component: () => import('@/pages/coupon/index'),
      meta: {requiresAuth: true},
      children: [
        { name: 'coupon-new', path: 'new', component: () => import('@/pages/coupon/_id') },
        { name: 'coupon-detail',
          path: ':_id',
          component: () => import('@/pages/coupon/_id')
        }
      ]
    },*/
    {
      path: "/coupon",
      name: "COUPON",
      component: () => import("@/pages/coupon-new/index"),
      meta: { requiresAuth: true },
      children: [
        /*{ name: 'coupon-new', path: 'new', component: () => import('@/pages/coupon/_id') },*/
        {
          name: "coupon-detail",
          path: ":_id",
          component: () => import("@/pages/coupon-new/_id"),
        },
      ],
    },
    {
      path: "/wide/list",
      name: "wide-list",
      component: () => import("@/pages/wide/index"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        breadCrumbs: [
          {
            to: "/list",
            text: "리스트",
          },
        ],
      },
      children: [
        /*{
          name: 'list-specific',
          path: ':_id',
          component: () => import('@/pages/ad/_id')
        }*/
      ],
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/pages/dashboard/index"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        breadCrumbs: [
          {
            to: "/",
            text: "dashboard",
          },
        ],
      },
      children: [
        /*{
          name: 'list-specific',
          path: ':_id',
          component: () => import('@/pages/ad/_id')
        }*/
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const { matched, path, query } = to;
  /*
  if (matched.some(({meta}) => meta.requiresAuth)) {
    const {result} = await Store.dispatch('verify')
    if (!result) return next(`/login?url=${encodeURIComponent(path)}`)
  }
  return next()
  */

  matched.some(async function (route) {
    if (route.meta.requiresAuth) {
      const { result } = await Store.dispatch("verify");
      if (!result) return next(`/login?url=${encodeURIComponent(path)}`);
    }

    if (route.meta.adminAuth) {
      const level = Store.getters.level;

      if (level === "wide") {
        const { page, perPage } = query;
        return next({ path: `/wide/list`, query: { page, perPage } });
      }

      if (level != "superAdmin") {
        Vue.toasted.error("권한이 없습니다. 관리자에게 문의하세요.", {
          duration: 3000,
        });
        return next(`/answer`);
      }
    }
  });
  return next();
});

export default router;
