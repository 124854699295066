<template>
  <div>
    <b-navbar toggleable="lg" type="dark" id="top-nav">
      <b-navbar-brand href="#">SAMPICK ADMIN</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            right
            v-if="$store.getters.level == 'superAdmin'"
          >
            <template #button-content> 광고 관리 </template>
            <b-dropdown-item to="/ad/list" :active="activeLink.ad"
              >리스트</b-dropdown-item
            >
            <b-dropdown-item to="/ad/traffic" :active="activeLink.traffic"
              >트래픽</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="$store.getters.level == 'superAdmin'">
            <template #button-content> 회원 관리 </template>
            <b-dropdown-item to="/users/list" :active="activeLink.users"
              >회원 리스트</b-dropdown-item
            >
            <b-dropdown-item
              to="/users/member/list"
              :active="activeLink.memberUsers"
              >멤버회원 리스트</b-dropdown-item
            >
            <!--            <b-dropdown-item to="/users/message" :active="activeLink.message">메세지</b-dropdown-item>-->
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="$store.getters.level !== 'wide'">
            <template #button-content> 게시물 관리 </template>
            <b-dropdown-item to="/answer" :active="activeLink.answer"
              >1:1문의 답변</b-dropdown-item
            >
            <b-dropdown-item to="/news-letter" :active="activeLink.newsLetter"
              >뉴스레터</b-dropdown-item
            >
            <b-dropdown-item to="/faq" :active="activeLink.faq"
              >FAQ</b-dropdown-item
            >
            <b-dropdown-item to="/notice" :active="activeLink.notice"
              >공지사항</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="$store.getters.level == 'superAdmin'">
            <template #button-content> 결제 관리 </template>
            <b-dropdown-item to="/payment" :active="activeLink.payment"
              >결제 관리</b-dropdown-item
            >
            <b-dropdown-item to="/coupon" :active="activeLink.coupon"
              >쿠폰 관리</b-dropdown-item
            >
            <b-dropdown-item to="/dashboard" :active="activeLink.dashboard">사후정산내역 관리</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="$store.getters.level == 'superAdmin'">
            <template #button-content> 기타 관리 </template>
            <b-dropdown-item to="/pointEvent" :active="activeLink.pointEvent"
              >포인트 이벤트 관리</b-dropdown-item
            >
            <b-dropdown-item to="/wide/list" :active="activeLink.wide"
              >wide 리스트 관리</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--          <b-nav-form>-->
          <!--            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
          <!--            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
          <!--          </b-nav-form>-->

          <b-nav-item class="my-auto" v-b-toggle.clients>
            <b-avatar
              badge-variant="success"
              icon="wifi"
              :badge="`${clients.length.toString()}`"
            ></b-avatar>
            <!--            <font-awesome-icon v-if="connected" icon="wifi" />-->
          </b-nav-item>
          <b-nav-item>
            <b-avatar variant="transparent" :src="profileImage" class="mr-3" />
          </b-nav-item>
          <b-nav-item-dropdown class="my-auto" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ userId }}</em>
            </template>
            <b-dropdown-item href="#" @click="signOut"
              >Sign Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar
      id="clients"
      title="현재 접속자"
      bg-variant="dark"
      text-variant="light"
      shadow
    >
      <div class="px-3 py-2">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center p-2"
            style="color: black"
            v-for="(i, idx) of clients"
            v-bind:key="`client${idx}`"
          >
            {{ i.userId }}
            <b-button
              variant="success"
              @click="$router.push(`/users/list/${i._id}/auth-history`)"
              >조회</b-button
            >
          </b-list-group-item>
        </b-list-group>
        <!--        <div v-for="(i, idx) of clients" v-bind:key="`client${idx}`">
          {{ i.userId }}
        </div>-->
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { VueSocketIO, SocketIO, url } from "@/plugin/vue-socket";
import Vue from "vue";
export default {
  name: "top",
  computed: {
    connected() {
      const { connected } = this.$socket;
      return connected ? connected : this.firstConnected;
    },
    token() {
      return this.$store.getters.token;
    },
    userId() {
      return this.$store.getters.userId;
    },
    profileImage() {
      return this.$store.getters.profileImage;
    },
    clients() {
      return this.$store.getters.connectList;
    },
    activeLink() {
      return {
        ad: this.$route.path.startsWith("/list"),
        traffic: this.$route.path.startsWith("/traffic"),
        users: this.$route.path.startsWith("/users/list"),
        message: this.$route.path.startsWith("/users/message"),
        answer: this.$route.path.startsWith("/answer"),
        newsLetter: this.$route.path.startsWith("/news-letter"),
        payment: this.$route.path.startsWith("/payment"),
        faq: this.$route.path.startsWith("/faq"),
        notice: this.$route.path.startsWith("/notice"),
        pointEvent: this.$route.path.startsWith("/pointEvent"),
        memberUsers: this.$route.path.startsWith("/users/member/list"),
        coupon: this.$route.path.startsWith("/coupon"),
        wide: this.$route.path.startsWith("/wide/list"),
        dashboard: this.$route.path.startsWith('/dashboard')
      };
    },
    connectList() {
      return this.$store.getters.connectList;
    },
    listOptions() {
      return this.connectList.map((c) => ({
        text: `${c.userId}(${c.socketId})`,
        value: c._id,
      }));
    },
  },
  data: () => ({
    firstConnected: false,
  }),
  created() {
    this.socketConn();
  },
  sockets: {
    connect: function () {
      console.log("conn");
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
    },
    socketConn() {
      if (this.$socket === undefined && this.token) {
        const options = {
          connection: SocketIO(url, {
            query: {
              token: this.token,
            },
            reconnect: true,
            reconnectionDelay: 5000,
            maxReconnectionAttempts: 5,
            transports: ["websocket"],
          }),
          vuex: {
            store: this.$store,
            actionPrefix: "socket_",
            mutationPrefix: "socket_",
          },
        };
        this.firstConnected = true;
        Vue.use(new VueSocketIO(options));
      }
    },
  },
};
</script>
<style scoped lang="scss">
#top-nav {
  background-color: #5b2da3;
}
</style>
