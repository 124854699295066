import axios from '@/plugin/axios'
import router from '@/router/index'
export default {
  state: {
    _id: null,
    token: null,
    userId: null,
    profileImage: null,
    nickName: null,
    autoLogin: false,
    level : null,
  },
  getters: {
    _id: state => {
      return state._id
    },
    token: state => {
      return state.token
    },
    userId: state => {
      return state.userId
    },
    profileImage: state => {
      return state.profileImage
    },
    nickName: state => {
      return state.nickName
    },
    autoLogin: state => {
      return state.autoLogin
    },
    level: state => {
      return state.level
    },
  },
  mutations: {
    logout (state) {
      state.token = null
      state.userId = null
      state.nickName = null
      state.profileImage = null
      state.autoLogin = false
      state.level = null;
    },
    loginSuccess (state, {token, userId, nickName, profileImage, autoLogin, _id, level}) {
      state._id = _id
      state.token = token
      state.userId = userId
      state.nickName = nickName
      state.profileImage = profileImage
      state.autoLogin = autoLogin
      state.level = level;
    },
    tokenRefresh (state, {token, userId, nickName, profileImage, autoLogin, _id, level}) {
      state._id = _id
      state.token = token
      state.userId = userId
      state.nickName = nickName
      state.profileImage = profileImage
      state.autoLogin = autoLogin
      state.level = level;
    }
  },
  actions: {
    async signIn ({ commit }, params) {
      try {
        const { url } = params
        const { data } = await axios({
          url: `/admin/login`,
          method: 'POST',
          data: params
        })
        const {result, token, userId, nickName, profileImage, autoLogin, error, _id, level} = data
        if (result && token) {
          commit('loginSuccess', {token, userId, nickName, profileImage, autoLogin, _id, level})
          if (level === 'wide') await router.replace({path: '/wide/list'})
          await router.replace({path: level === 'admin' ? '/answer' : url ? decodeURIComponent(url) : '/ad/list'})
        } else {
          return {
            result, error
          }
          // Vue.toasted.error(error, {
          //   iconPack: "fontawesome",
          //   icon: 'check'
          // })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async signOut ({ commit }) {
      commit('logout')
      await router.replace({path: '/login'})
    },
    async verify ({ commit }) {
      try {
        const { data } = await axios({
          url: `/admin/verify`,
          method: 'GET'
        })
        const {result, token, userId, nickName, profileImage, autoLogin, error, _id, level} = data
        if (result && token) commit('tokenRefresh', { token, userId, nickName, profileImage, autoLogin, _id ,level})
        return { result }
      } catch (e) {

      }
    }
  }
}
