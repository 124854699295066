const state = () => ({
  socketId: null,
  connDate: null,
  connectList: [],
  newVideo: {
    complete: [],
    progress: [],
    ready: []
  }
})

const getters = {
  newVideo: (state) => {
    return state.newVideo
  },
  connectList: (state) => {
    return state.connectList
  },
  socketId: ({socketId}) => {
    return socketId
  }
}

const mutations = {
  welcome (state, {date, socketId}) {
    state.connDate = date
    state.socketId = socketId
  },
  connectList (state, {list}) {
    state.connectList = list
  },
  leave (state, {_id}) {
    const index = state.connectList.findIndex(c => c._id === _id)
    if (index !== -1) state.connectList.splice(index, 1)
  },
  socketLogin (state, {socketId}) {
    state.socketId = socketId
  },
  newVideo (state, {data}) {
    const {status} = data
    state.newVideo[status].push(data)
  },
  socketLogout (state) {
    state.socketId = null
    state.connDate = null
    // window.location.replace('/')
  }
}

const actions = {
  'socket_new_connect' ({commit}, {list}) {
    console.log(list)
    commit('connectList', {list})
  },
  'socket_leave' ({commit}, {_id, id, token, userId, nickName, profileImage}) {
    commit('leave', {_id, id, token, userId, nickName, profileImage})
  },
  'socket_new_video' ({commit}, {data}) {
    commit('newVideo', {data})
  },
  'socket_welcome' ({commit}, {message, date, image, socketId, nickName, _id, userId}) {
    console.log(socketId)
    commit('welcome', {date, socketId})
    if (image) {
      const imageElement = new Image()
      imageElement.onload = () => {
      //   this.$toast(content, {
      //     position: 'bottom-right',
      //     timeout: 5000,
      //     icon: false,
      //     closeButton: false,
      //     type: 'info'
      //   })
      }
      imageElement.src = image
    } else {
      // this.$toast(content, {
      //   position: 'bottom-right',
      //   timeout: 5000,
      //   icon: false,
      //   closeButton: false,
      //   type: 'info'
      // })
    }
  },
  socketLogout ({commit}) {
    console.log('socketLogout')
    commit('socketLogout')
  },
  // 'socket_message' ({commit}, {message, fromUserId, date}) {
  //   console.log(message, fromUserId, date)
  // },
  'socketLogin' ({commit}, {socketId}) {
    commit('socketLogin', {socketId})
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
