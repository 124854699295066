import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'
import Vue from 'vue'
const baseURL = process.env.NODE_ENV === 'development' ? '/api' : '/api'
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000
})

instance.interceptors.request.use(config => {
  if (store) {
    const token = store.getters.token
    if (token) config.headers['Authorization'] = token
  }
  return config
})

instance.interceptors.response.use(response => {
  const {data} = response
  const {result, message} = data
  if (!result) {
    Vue.toasted.error(message, {
      duration: 3000
    })
  }
  return response
}, error => {
  const { response } = error
  const { status , data} = response
  if (data) {
    const {error} = data
    // Vue.toasted.error(message)
  }
  if (status === 401) {
    store.dispatch('signOut')
    //return router.replace({path: '/login'})
  }
})

export default instance
