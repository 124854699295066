import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import VueAxios from 'vue-axios'
import axios from '@/plugin/axios'
import topNav from '@/components/top'
import store from '@/store/index'
import Toasted from 'vue-toasted'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {ServerTable, ClientTable, Event} from 'vue-tables-2'
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2'
import UUID from "vue-uuid"


library.add(fas)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons )
Vue.use(Toasted)
Vue.use(VCalendar)
Vue.use(VueClipboard)
Vue.use(UUID)

Vue.config.productionTip = false

Vue.component('top-nav', topNav)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.use(ClientTable, [{}], [false], ['bootstrap4'], [{}]);

Vue.mixin({
  data: () => ({
    API_KEY: '90mnf5c5vmt6alx58fshtdzfeeqwvstl7ue7kvz5ks8ax3kt',
    EDITOR_OPTIONS: {
      height: '480',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
    },
  }),
  methods: {
    async search (filter) {
      if (filter) this.filter = filter
      this.filter.randNo = Math.floor(Math.random() * 899999 + 100000)
      await this.$router.push({
        path: this.$route.path,
        query: this.filter
      })
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
