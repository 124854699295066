<template>
  <div id="app">
    <router-view
      :key="fullPath"
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    fullPath () {
      return this.$route.fullPath
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
html, body {
  height: 100%;
  margin: 0;
}

#app {
  font-family: 'Nanum Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
