import Vue from 'vue'
import Vuex from 'vuex'
import defaultStore from './default'
import socketStore from './socket'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    def: defaultStore,
    socket: socketStore,
  },
  plugins: [
    createPersistedState({
      paths: ['def.token']
    })
  ],
})
export default store
